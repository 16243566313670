import "jquery/dist/jquery";
import '@popperjs/core/dist/esm/popper';
import "bootstrap/dist/js/bootstrap.bundle";

import "~/shared/prefilter";

import "~/scss/main.scss";

import "~/assets/images/background.jpg";
import "~/assets/images/uca_logo.png";
import "~/assets/images/inria_logo.png";
import "~/assets/images/inserm_logo.png";
import "~/assets/images/skema_logo.png";
import "~/assets/images/eurecom_logo.png";
import "~/assets/images/cnrs_logo.png";
import "~/assets/images/centrale_logo.png";
import "~/assets/images/eae_logo.png";
import "~/assets/images/3ia_logo.png";
import "~/assets/images/3ia_logo_round.png";
import "~/assets/images/3ia_logo_round_light.png";

/* === FOOTER ANIMATIONS === */
const members3ia = $("#members");
const firstScrollDiv = members3ia.children()[0];

const clonedMembers3ia = members3ia.clone(); // CLONING CONTAINER FOR INFINITE REPEAT
const secondScrollDiv = clonedMembers3ia.children()[0];
members3ia.parent().append(clonedMembers3ia);

let bannerOverflow = members3ia[0].offsetWidth < firstScrollDiv.scrollWidth;

const initPositions = () => {
    firstScrollDiv.style.transform = `translateX(0px)`;
    secondScrollDiv.style.transform = `translateX(${firstScrollDiv.scrollWidth}px)`;
};

// SCROLL FUNCTION
let scrollPosition = 0;
const scrollLoop = ({scrollSpeed = 1}) => {
    if(bannerOverflow) {
        scrollPosition += scrollSpeed;
        if (scrollPosition > firstScrollDiv.scrollWidth) {
            scrollPosition = 0;
            firstScrollDiv.style.transform = `translateX(-${scrollPosition}px)`;
            secondScrollDiv.style.transform = `translateX(${firstScrollDiv.scrollWidth - scrollPosition}px)`;
        }
        firstScrollDiv.style.transform = `translateX(-${scrollPosition}px)`;
        secondScrollDiv.style.transform = `translateX(${firstScrollDiv.scrollWidth - scrollPosition}px)`;
    } else {
        initPositions();
    }
    requestAnimationFrame(scrollLoop);
};

$(window).on("resize", () => {
    initPositions();
    bannerOverflow = $("#members")[0].offsetWidth < $("#members").children()[0].scrollWidth;
});

initPositions();

// Start the continuous scroll loop
setTimeout(() => { scrollLoop({ scrollSpeed: 1 }); }, 4000);